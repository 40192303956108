











































































































































import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter, ListingOrderBy } from 'client-website-ts-library/filters';
import { ListingStatus, ListingCategory } from 'client-website-ts-library/types';
import { View } from 'client-website-ts-library/plugins';

import Form from '@/components/Forms/Form.vue';
import Embeddable from '@/components/Common/Embeddable.vue';
import { EmbeddedContent, EmbeddedContentType } from 'client-website-ts-library/types/EmbeddedContent/EmbeddedContent';
import Listings from '../components/Listings.vue';
import VideoBanner from '../components/VideoBanner.vue';

@Component({
  components: {
    Listings,
    VideoBanner,
    Embeddable,
    Form,
  },
})
export default class AnyAgentCanSellYourHome extends Mixins(View) {
  private listingFilter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Sold,
      ListingStatus.Leased,
    ],
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
      ListingCategory.Rural,
      ListingCategory.Commercial,
      ListingCategory.CommercialLand,
      ListingCategory.Business,
    ],
    PageSize: 8,
    OrderByStatements: [
      ListingOrderBy.CreateDateDesc,
    ],
  });

  private readonly promo: EmbeddedContent = {
    Type: EmbeddedContentType.Youtube,
    EmbedId: 'hmpjap5VUZY',
  }

  mounted() {
    let scr: HTMLScriptElement | null = document.getElementById('scrCoreLogicIngegration') as HTMLScriptElement | null;
    if (scr) scr.remove();

    setTimeout(() => {
      scr = document.createElement('script');
      scr.src = 'https://dpr.leadplus.com.au/main.js';
      scr.async = true;
      scr.defer = true;
      scr.id = 'scrCoreLogicIngegration';

      scr.onload = () => {
        console.log('Core Logic Integration Loaded');
      };

      document.body.append(scr);
    }, 500);
  }
}
